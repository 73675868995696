import React, { useState } from "react";
import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { EventInput } from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import Layout from "../../components/global/Layout/Layout";
import { ICalendarProps, IEvent } from "../../@types/ICalendar";
import useMobileDetect from "../../hooks/useMobileDetect";
import { displayTimeFormat, formatDate } from "../../services/dates";
import EventDetailModal from "../../components/common/Modal/EventDetailModal/EventDetailModal";
import { container } from "./Calendar.module.scss";
import { ICalendarCardProps } from "../../components/common/Cards/CalendarCard/CalendarCardProps";
import { PageProps } from "gatsby";

interface ICalendarPageContext {
  events: {
    node: {
      fields: ICalendarCardProps;
    };
  }[];
}

const Calendar = (props: PageProps<null, ICalendarPageContext>) => {
  const [eventDetails, setEventDetails] = useState<ICalendarProps>({
    selectedEvent: null,
    isEventDetailModal: false,
  });
  const isMobile = useMobileDetect();
  const pageContext: ICalendarPageContext = props.pageContext;
  const events: EventInput[] = pageContext?.events.map((event: IEvent) => {
    const timeIndex = event.node.fields.eventDate?.indexOf("T");
    let date = event.node.fields.eventDate;
    if (event.node.fields.isAllDayEvent) {
      date = event.node.fields.eventDate?.substring(0, timeIndex);
    }
    const obj = {
      id: event.node.fields.id,
      title: event.node.fields.title,
      start: date,
      allDay: event.node.fields.isAllDayEvent,
      end: event.node.fields.endDate,
    };
    return obj;
  });
  const handleEventClick = (e: EventClickArg) => {
    const selectedEvent = pageContext?.events.find(
      event => event.node.fields.id === e.event._def.publicId
    );
    const eventObj = {
      title: selectedEvent?.node.fields.title,
      time: `${formatDate(
        new Date(selectedEvent?.node.fields.eventDate),
        displayTimeFormat
      )}- ${formatDate(
        new Date(selectedEvent?.node.fields.endDate),
        displayTimeFormat
      )}`,
      description: selectedEvent?.node.fields.description,
      rlhCallToAction: selectedEvent?.node.fields?.rlhCallToAction,
      eventDate: selectedEvent?.node.fields.eventDate,
      endDate: selectedEvent?.node.fields.endDate,
    };
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: true,
      selectedEvent: eventObj,
    });
  };
  const hideEventDetail = () => {
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: false,
      selectedEvent: null,
    });
  };
  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    meridiem: "short",
  };
  return (
    <Layout>
      <div className={container}>
        {eventDetails?.isEventDetailModal && (
          <>
            <EventDetailModal
              isShow={true}
              onHide={hideEventDetail}
              eventDetails={eventDetails.selectedEvent}
            />
          </>
        )}

        <FullCalendar
          plugins={[dayGridPlugin, listPlugin]}
          initialView={isMobile ? "listMonth" : "dayGridMonth"}
          editable={true}
          selectable={true}
          eventClick={handleEventClick}
          initialEvents={events}
          eventTimeFormat={eventTimeFormat}
        />
      </div>
    </Layout>
  );
};
export default Calendar;
